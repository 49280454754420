/**
 * store.css
 * Stripe Payments Demo. Created by Romain Huet (@romainhuet).
 */

.with-state{
  margin-bottom: 10px;
}

.with-state > label{
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkout-page {
  /*margin: 0;*/
  /*padding: 0;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*-webkit-text-size-adjust: none;*/
  /*box-sizing: border-box;*/

  /*font-size: 15px;*/
  /*line-height: 1.4em;*/
  /*color: #525f7f;*/
  /*background: #f8fbfd;*/
  /*align-items: center;*/
}

/*body {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    background: #f8fbfd;*/
/*    align-items: center;*/

/*}*/

/* Overall Container */




.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
  color: #687188;
  padding: 0;
  vertical-align: middle;
}
.custome-checkbox .form-check-input {
  display: none;
}


.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}


.custome-checkbox .form-check-label span {
  vertical-align: middle;
}
.custome-checkbox .form-check-label::before {
  content: "";
  border: 2px solid #ced4da;
  height: 17px;
  width: 17px;
  margin: 0px 8px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.custome-checkbox input[type="checkbox"]:checked + .form-check-label::after {
  opacity: 1;
}
.custome-checkbox input[type="checkbox"] + .form-check-label::after {
  content: "";
  width: 11px;
  position: absolute;
  top: 50%;
  left: 3px;
  opacity: 0;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -moz-transform: translateY(-65%) rotate(-45deg);
  -webkit-transform: translateY(-65%) rotate(-45deg);
  transform: translateY(-65%) rotate(-45deg);
}
.custome-checkbox input[type="checkbox"]:checked + .form-check-label::before {
  background-color: #FF324D;
  border-color: #FF324D;
}
.custome-checkbox input[disabled] + .form-check-label,
.custome-radio input[disabled] + .form-check-label {
  color: #d0d0d0;
}
.custome-checkbox input[disabled] + .form-check-label::before,


.custome-checkbox input[type="checkbox"]:checked + .form-check-label::before {
  background-color: #FF324D;
  border-color: #FF324D;
}

.checkout-page fieldset label:not(.error) {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  color: #8898aa;
  font-weight: 400;
}

.checkout-page fieldset label.description {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  align-items: normal;
  justify-content: left;
  padding: 10px 0;
  margin-left: 30px;
  color: #8898aa;
  font-weight: 400;
}

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
}

.checkout-page fieldset label.error {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  color: red;
  font-weight: 400;

}

#main {
  width: 100%;
  text-align: center;
  transition: width 0.3s ease-in-out;
}

/*#main.checkout:not(.success):not(.error) {*/
/*    width: calc(100% - 450px);*/
/*}*/

/* Header */

.checkout-page .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  /*background: #fff url(/images/logo.svg) center center no-repeat;*/

}

.checkout-page .header a.shop,
.checkout-page .header a.github {
  margin: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #666ee8;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-decoration: none;
}

.checkout-page .header a:hover {
  text-decoration: underline;
}

/* Checkout */

#checkout {
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 0;
}

#main.checkout #checkout {
  visibility: visible;
  opacity: 1;
}

.border-box-pay {
  box-shadow: 15px 0 30px 0px rgb(0 0 0 / 18%);
  /**
  15px 0 30px 0 rgb(0 0 0 / 18%)
   */
  clip-path: inset(0px -0px 0px -15px);
}

.checkout-page section {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
}

.checkout-page h1 {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 500;
}

.checkout-page h2 {
  margin: 15px 0;
  color: #32325d;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 13px;
  font-weight: 500;
}

/* Payment Request */

#payment-request {
  min-height: 100px;
  padding: 20px 0;
  transition: visibility 0s, opacity 0.3s ease-in;
}


#payment-form {
  margin: 0 -30px;
  padding: 20px 30px 30px;
  border-radius: 4px;

}

/* Form */

.checkout-page fieldset {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
  0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 15px;

}

.checkout-page fieldset label {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  color: #8898aa;
  font-weight: 400;
}

.checkout-page fieldset label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}

.checkout-page fieldset label.state {
  display: inline-flex;
  width: 75%;
}

.checkout-page fieldset:not(.with-state) label.state {
  display: none;
}

.checkout-page fieldset label.zip {
  display: inline-flex;
  padding-right: 60px;
}

.checkout-page fieldset:not(.with-state) label.zip {
  width: 100%;
}

.checkout-page fieldset label span {
  min-width: 100px;
  padding: 0 15px;
  text-align: right;
}

.checkout-page fieldset .redirect label span {
  width: 100%;
  text-align: center;
}

.checkout-page p.instruction {
  display: inline-table;
  margin-top: -32px;
  padding: 0 5px;
  text-align: center;
}

.checkout-page p.tip {
  margin: -10px auto 10px;
  padding: 5px 0 5px 30px;
  font-size: 14px;
  /*background: url(/images/tip.svg) left center no-repeat;*/
}

.checkout-page span#generate {
  cursor: pointer;
  color: #525f7f;
  text-decoration: underline;
}
.checkout-page span#generate:hover {
  text-decoration: none;
}

.checkout-page .field {
  flex: 1;
  padding: 0 15px;
  background: transparent;
  font-weight: 400;
  color: #31325f;
  outline: none;
  cursor: text;
}

.checkout-page .field::-webkit-input-placeholder {
  color: #aab7c4;
}
.checkout-page .field::-moz-placeholder {
  color: #aab7c4;
}
.checkout-page .field:-ms-input-placeholder {
  color: #aab7c4;
}

.checkout-page fieldset .select::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  pointer-events: none;
}

.checkout-page input {
  flex: 1;
  border-style: none;
  outline: none;
  color: #313b3f;
}

.checkout-page select {
  flex: 1;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color: #313b3f;
  cursor: pointer;
  background: transparent;
}

.checkout-page select:focus {
  color: #666ee8;
}

::-webkit-input-placeholder {
  color: #cfd7e0;
}
::-moz-placeholder {
  color: #cfd7e0;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #cfd7e0;
}

.checkout-page input:-webkit-autofill,
.checkout-page select:-webkit-autofill {
  -webkit-text-fill-color: #666ee8;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out 1s;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

#card-element {
  margin-top: -1px;
}

#ideal-bank-element {
  padding: 0;
}

.checkout-page button {
  display: block;
  background: #666ee8;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  width: 100%;
  height: 40px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease;
}

.checkout-page button:focus {
  background: #555abf;
}

.checkout-page button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
  0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

.checkout-page button:active {
  background: #43458b;
}

#country {
  display: flex;
  align-items: center;
}

#country select {
  margin: 0 -15px 0 -30px;
  padding: 0 15px 0 30px;
}

#country::before {
  display: inline-flex;
  content: '';
  width: 21px;
  height: 15px;
  /*background: url(/images/flags.png);*/
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

/* Payment Methods */

#payment-methods {
  margin: 0 0 20px;

}

#payment-methods li {
  display: none;
}

#payment-methods li.visible {
  display: inline-block;
  margin: 0 20px 0 0;
  list-style: none;
}

#payment-methods input {
  display: none;
}

#payment-methods label {
  display: flex;
  flex: 1;
  cursor: pointer;
}

#payment-methods input + label {
  position: relative;
  padding: 5px 0;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
}

#payment-methods label::before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 0;

  opacity: 0;
  transform: scaleX(0);
  transition: all 0.25s ease-in-out;
}

#payment-methods label:hover {
  color: #6772e5;
  cursor: pointer;
}

#payment-methods input:checked + label {
  color: #6772e5;
}

#payment-methods label:hover::before,
#payment-methods input:checked + label::before {
  opacity: 1;
  transform: scaleX(1);
}

#payment-methods,
.payment-info {
  display: none;
}


.payment-info.ideal {
  margin-bottom: 0;
}

#payment-methods.visible,
.payment-info.visible {
  display: block;
}


.payment-info p.notice {
  font-size: 14px;
  color: #8898aa;
  text-align: left;
}

#wechat-qrcode img {
  margin: 0 auto;
}

.cards-errors {
  height: 20px;
  margin: 0 auto 20px;
  padding-left: 20px;
  color: #e25950;
  transform: translateY(10px);
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-size: 15px 15px;
}

.element-errors.visible {
  opacity: 1;
  transform: none;
}

#iban-errors {
  margin-top: -20px;
}

/* Order Summary */

#summary {
  overflow: auto;
  height: 100%;
  background: #fff;
  box-shadow: 0 2px 19px 4px rgba(0, 0, 0, 0.04);
  transition: right 0.3s ease-in-out;
}

#main.checkout:not(.success):not(.error) + #summary {
  right: 0;
}

#summary .header {
  background: #fff;
}

#summary h1 {
  margin: 0 30px;
  line-height: 80px;
  font-weight: 400;
}

#summary p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

#summary .discount p {
  margin-top: 0;
}

#summary .line-item {
  display: flex;
  flex-direction: row;
  padding: 30px 30px 0 30px;
}

#summary .line-item .image {
  display: block;
  width: 80px;
  height: 80px;
  margin-right: 15px;
  background: #f6f9fc;
  border-radius: 3px;
}

#summary .line-item .label {
  flex: 1;
  text-align: left;
}

#summary .line-item .product {
  color: #424770;
}

#summary .line-item .sku {
  font-size: 14px;
  color: #8898aa;
}

#summary .line-item .count,
#summary .line-item .price {
  font-size: 14px;
  padding-left: 10px;
  text-align: right;
  line-height: 24px;
}

#summary .line-item .count {
  color: #8898aa;
}

#summary .line-item .price {
  color: #8ba4fe;
  font-weight: bold;
}

p.discount {
  color: tomato !important;
}

p.final-price {
  color: limegreen !important;
}

#summary .line-item.subtotal {
  margin-top: 30px;
  margin-bottom: 0;
  padding-top: 10px;
  border-top: 1px solid #f3f3ff;
}

#summary .line-item.shipping {
  padding-top: 0;
}

#summary .line-item.total {
  margin-top: 15px;
  margin-bottom: 30px;
  padding-top: 15px;
  font-size: 21px;
  border-top: 1px solid #f3f3ff;
}

#summary .line-item.total .label,
#summary .line-item.total .price {
  color: #424770;
  font-size: 24px;
  font-weight: 400;
  line-height: inherit;
}

#demo {
  padding: 15px;
  margin: -15px -15px 0;
  background: #f6f9fc;
  border-radius: 5px;
}

#demo p.label {
  margin: 0 0 10px;
  color: #666ee8;
}

#demo .note {
  display: block;
  margin: 10px 0 0;
  font-size: 14px;
}

#demo p.note a,
#demo p.note em {
  font-size: 14px;
}

#demo p.note a:hover {
  text-decoration: none;
}

.card-number {
  padding-left: 8px;
  white-space: nowrap;
  font-family: Source Code Pro, monospace;
  color: #0d2b3e;
  font-weight: 500;
}

.card-number span {
  display: inline-block;
  width: 8px;
}

/* Order Confirmation */

#confirmation {
  display: flex;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  visibility: hidden;
  overflow-x: hidden;
  opacity: 0;
  background: #f8fbfd;
  text-align: left;
  transition: visibility 0s, opacity 0.5s linear 0.5s;
}

#main.success #confirmation,
#main.error #confirmation {
  visibility: visible;
  opacity: 1;
}

#main.success #order,
#main.error #order {
  visibility: hidden;
  opacity: 0;
}

#confirmation h1 {
  font-size: 42px;
  font-weight: 300;
  color: #6863d8;
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}

#confirmation .status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 75px 0 275px;
  max-width: 75%;
  height: 350px;
  margin: 100px auto;
  background: #fff 75px center no-repeat;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  border-radius: 6px;
}

#confirmation .status.error {
  display: none;
}

#confirmation .status p {
  margin: 0 0 15px;
}

#confirmation .status li {
  margin-bottom: 5px;
  list-style: none;
}

#main.success:not(.processing) #confirmation .status.processing,
#main.success:not(.receiver) #confirmation .status.receiver {
  display: none;
}

#main.processing #confirmation .status.success,
#main.receiver #confirmation .status.success {
  display: none;
}

#main.error #confirmation .status.success,
#main.error #confirmation .status.processing,
#main.error #confirmation .status.receiver {
  display: none;
}

#main.error #confirmation .status.error {
  display: flex;
}

#main.error #confirmation .error-message {
  font-family: monospace;
}


/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.hidden-stripe{
  display: none;
}

.center-text{
  margin-left: auto;
  margin-right: auto;
}

.blocktext {
  margin-left: 20px;
  margin-right: 14px;
}



/* Media Queries */

@media only screen and (max-width: 1024px) {
  #main.checkout:not(.success):not(.error) {
    width: calc(100% - 320px);
  }
  #summary {
    width: 320px;
    right: -320px;
  }
  #main.checkout:not(.success):not(.error) + #summary {
    right: 0;
  }
  #summary .line-item p {
    margin-top: 0;
  }
  #summary .line-item .image {
    width: 40px;
    height: 40px;
  }
  #summary .line-item .label {
    margin: 0;
  }
}

@media only screen and (max-width: 800px) {
  #main.checkout:not(.success):not(.error) {
    width: 100%;
  }
  #payment-request {
    padding-top: 0;
    min-height: 80px;
  }
  /*#summary {*/
  /*    display: none;*/
  /*}*/

  /*#order-total{*/
  /*    display: none;*/
  /*}*/

  #confirmation .status {
    width: auto;
    height: auto;
    margin: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .checkout-page .header {
    height: 60px;
    background-size: 40px 40px;
  }
  .checkout-page .header a.shop,
  .checkout-page .header a.github {
    display: none;
  }
  #payment-request {
    min-height: 0;
    padding-right: 15px;
    padding-left: 15px;
  }
  #payment-form {
    padding: 0 15px;
    border: 1px solid #e8e8fb;
    border-width: 2px 0 0 0;
    border-radius: 0;
    margin: 0;
  }

  .payment-info span {
    display: none;
  }
  .checkout-page fieldset {
    margin-bottom: 15px;
  }
  .checkout-page fieldset label.state,
  .checkout-page fieldset label.zip {
    display: flex;
    width: inherit;
    padding: 10px 0;
  }
  .checkout-pagep.instruction {
    margin-top: -12px;
    font-size: 14px;
  }
  .checkout-pagep.tip {
    margin-bottom: 0;
    font-size: 13px;
  }
  #country::before {
    display: none;
  }
  #checkout {
    margin-bottom: 0;
  }
  #confirmation .status {
    width: auto;
    height: auto;
    padding: 120px 15px 15px;
    background: #fff center 15px no-repeat;
    background-size: 68px 86px;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15);
    border-radius: 6px;
  }
  #confirmation h1 {
    text-align: center;
  }
}
